import React, { useState, useEffect } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, AccessRight, WebUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { func } from "prop-types";

/// <summary>
/// Author : -
/// </summary>
const WalletBalance = props => {
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [walletDetails, setWalletDetails] = useState(false);
  const _dispatch = useDispatch();
  const [loadingWalletFlag, setLoadingWalletFlag] = useState(true);
  const [wallets, setWallets] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [memberProductId, setMemberProductId] = useState(0);


  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {


    getWalletBalance()

  }, [walletDetails]);


  async function getWalletBalance() {
    ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?memberId=" + props.id).then((responseJson) => {
      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        console.log("hi" + responseJson[ApiKey._API_SUCCESS_KEY])
      } else {
        setWallets(responseJson[ApiKey._API_DATA_KEY]);
        setLoadingWalletFlag(false);
      }
    })
  }

  async function handleSubmit(mpid) {
    setMemberProductId(mpid);
    setShowConfirmation(true);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function resetRollover() {
    var responseJson = await ApiEngine.get(ApiUrl._API_RESET_WALLET_ROLLOVER_RECORD + "?mpid=" + memberProductId);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
      getWalletBalance()
    } else {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
      getWalletBalance()
    }
  }




  return (
    <div>
      {showConfirmation && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={t("ARE_YOU_SURE_TO_RESET_THE_ROLLOVER")}
          onConfirm={() => {
            resetRollover();
            setShowConfirmation(false);
          }}
          onCancel={() => {
            setShowConfirmation(false);
            setMemberProductId(0);
          }}
        >
          {t("UNABLE_TO_REVERT")}
        </SweetAlert>
      )}
      <table className="table">
        <th>
          <b>Wallet Info</b></th>
        <tr>
          <td><b>{t("CATEGORY")}</b></td>
          <td><b>{t("BALANCE")}</b></td>
          <td><b>{t("ROLLOVER_PROGRESS")}</b></td>
        </tr>
        {wallets.filter(x => x.productName != "Account Balance").map((wallet, index) => {
          return (
            <tr>
              <td><b>{wallet.productName}</b></td>
              <td>{parseFloat(wallet.balance).toFixed(2)}</td>
              <td>{parseFloat((wallet.rolloverTarget - wallet.rolloverBalance)).toFixed(2)} / {parseFloat(wallet.rolloverTarget).toFixed(2)}
                <br />
                <span className={parseFloat(wallet.rolloverBalance).toFixed(2) == 0 ? "badge badge-secondary badge-green clickable" : "badge badge-secondary badge-danger clickable-span "}
                  onClick={() => { parseFloat(wallet.rolloverBalance).toFixed(2) > 0 ? handleSubmit(wallet.mpid) : setShowConfirmation(false) }}
                >{parseFloat(wallet.rolloverBalance).toFixed(2) > 0 ? t("ACTIVE") : t("")}</span>
              </td>
            </tr>
          )
        }
        )
        }

      </table>
    </div>
  )
}

export default WalletBalance;