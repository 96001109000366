import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from "react-select/creatable";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  PanelType,
  LanguageKey,
  DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

/// <summary>
/// Author : -
/// </summary>
const CoinAdjustment = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setValue,
    trigger,
    control,
  } = useForm();
  const [memberOption, setMemberOption] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const [remarkOption, setRemarkOption] = useState([]);
  const [selectedMember, setSelectedMember] = useState();

  const [showPinEntry, setShowPinEntry] = useState(false);

  const [enteredAdjustmentData, setEnteredAdjustmentData] = useState({});
  const [enteredAdjustmentE, setEnteredAdjustmentE] = useState({});
  const [actionId, setActionId] = useState(1);
  const [publicPromotion, setPublicPromotion] = useState([]);
  const [exclusivePromotion, setExclusivePromotion] = useState([]);
  const [selectedPublicPromotion, setSelectedPublicPromotion] = useState(0);
  const [selectedExclusivePromo, setSelectedExclusivePromo] = useState(0);
  const [isMemberEntitled, setIsMemberEntitled] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [rolloverOption, setRolloverOption] = useState(1);
  const [providerList, setProviderList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [applyToOption, setApplyToOption] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [textLimit] = useState(2);
  const [tooltipText] = useState(`Input at least ${textLimit} characters to search`)
  const [displayToolTip, setDisplayToolTip] = useState(false);

  const [rolloverMultiplier, setRolloverMultiplier] = useState("");
  const [rolloverFixed, setRolloverFixed] = useState("");
  const [finalRolloverAmount, setFinalRolloverAmount] = useState("");
  const [amount, setAmount] = useState("");
  const [rolloverAmount, setRolloverAmount] = useState("");

  const handleInputChange = (selectedOption) => {
    if (textLimit > 0) {
      setDisplayToolTip(selectedOption.length > 0 && selectedOption.length < textLimit);
    }
  };

  const [isRequiredDeposit, setIsRequiredDeposit] = useState(false);
  const [isRolloverIncludeDeposit, setIsRolloverIncludeDeposit] = useState(false);

  /// <summary>
  /// Author : -
  /// Editted : -
  /// </summary>
  useEffect(() => {
    register({ name: "memberId" }, { required: "PLEASE_SELECT_MEMBER" });

    /// For The Size Difference of Modal And Page
    if (props.id) {
      setFullScreen(true);
    }

    init();
    initFormField();
  }, []);

  useEffect(() => {
    if (stringIsNullOrEmpty(rolloverOption) || selectedMember == null) {
      setFinalRolloverAmount("");
      setRolloverAmount(amount * rolloverMultiplier);
      return;
    }

    if (rolloverOption === "multi" && rolloverMultiplier !== "") {
      setFinalRolloverAmount(selectedMember.bonusWalletRollover + (amount * rolloverMultiplier));
      setRolloverAmount(amount * rolloverMultiplier);
      return;
    }

    if (rolloverOption === "fixed" && !stringIsNullOrEmpty(rolloverFixed)) {
      setRolloverAmount(rolloverFixed);
      return;
    }

    setRolloverAmount("");
    setFinalRolloverAmount("");

  }, [amount, rolloverMultiplier, rolloverFixed, selectedMember, rolloverOption]);

  /// <summary>
  /// Author : -
  /// Editted : - (Added Fetch to get IP Address)
  /// </summary>
  async function init() {
    try {
      _dispatch(setBusy());
      let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_REMARK);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        const remarkList = [];
        responseJson[ApiKey._API_DATA_KEY].forEach(function (remark) {
          remarkList.push({
            label: remark.description,
            value: remark.description,
          });
        });

        setRemarkOption(remarkList);
      } else {
        throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
      }

      await fetch(ApiUrl._API_GET_DEPOSIT_PROMOTION_ALL + "?all=true", {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            // console.log(responseJson[ApiKey._API_DATA_KEY].filter(n => !n.hidden))
            //setPublicPromotion(responseJson[ApiKey._API_DATA_KEY].filter(n => !n.hidden));

            let formattedData = responseJson[ApiKey._API_DATA_KEY]
              .filter(n => !n.hidden) // Filters out items where 'hidden' is true
              .map(m => {
                return {
                  label: m.name, // Group label
                  options: m.promotion
                    .filter(x =>
                      //// First condition: status is true, isPublished is true, hidden is either true or false, and isClaimable is true
                      //(x.status && x.isPublished && x.isClaimable) ||
                      //// Second condition: status is false, isClaimable is true, and exclude promotions with status true, isPublished false, and hidden false
                      //(x.isClaimable &&
                      //  !(x.status && !x.isPublished && !x.hidden)) &&
                      //// Third condition: Exclude promotions where status is false and isPublished is true, even if isClaimable
                      //!(x.status === false && x.isPublished === true)
                      x.status && x.isClaimable && !x.hidden
                    )
                    .map(s => {
                      return { label: s.name, value: s.id }; // Each item should have a label and value
                    })
                };
              });
            // console.log(formattedData)
            setPublicPromotion(formattedData)

            let formattedData2 = responseJson[ApiKey._API_DATA_KEY]
              .filter(n => n.hidden) // Filters out items where 'hidden' is true
              .map(m => {
                return {
                  label: m.name, // Group label
                  options: m.promotion
                    .filter(x =>
                      // First condition: status is true, isPublished is true, hidden is either true or false, and isClaimable is true
                      (x.status && x.isPublished && x.isClaimable) ||
                      // Second condition: status is false, isClaimable is true, and exclude promotions with status true, isPublished false, and hidden false
                      (x.isClaimable && !(x.status && !x.isPublished && !x.hidden)) &&
                      // Third condition: Exclude promotions where status is false and isPublished is true, even if isClaimable
                      !(x.status === false && x.isPublished === true)
                    )
                    .map(s => {
                      return { label: s.name, value: s.id }; // Each item should have a label and value
                    })
                };
              });
            setExclusivePromotion(formattedData2)

            //setExclusivePromotion(responseJson[ApiKey._API_DATA_KEY].filter(n => n.hidden));

          }
        });
    } catch (error) {
      _dispatch(showMessage(false, error));
    } finally {
      _dispatch(setIdle());
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const initFormField = () => {
    if (stringIsNullOrEmpty(props.id)) {
      setMemberId("");
      setValue("memberId", "");
      getMemberList("", null, { page: 1 });
      setSelectedMember(null);
    } else {
      setMemberId(props.id);
      setValue("memberId", props.id);
    }
    setValue("remark", "");
  };

  /// <summary>
  /// Author : -
  /// Editted : -
  /// </summary>

  //{ value: 1, label: "Coin Adjustment" },
  //{ value: 2, label: "Claim Public Promotion" },
  //{ value: 3, label: "Claim Exclusive Promotion" },
  //{ value: 4, label: "Claim Custom Promotion" },

  const submitForm = async (data, e) => {
    let responseJson = null;

    //Normal Coin Adjustment
    if (actionId == 1) {
      let params = {
        transactionTypeId: TransactionType._ADJUSTMENT,
        memberId: memberId,
        amount: data.amount,
        rollover: data.rollover,
        panel: PanelType._BACKOFFICE,
        remark: data.remark,
      };

      if (!stringIsNullOrEmpty(rolloverAmount)) {
        params.rollover = rolloverAmount;
      } else {
        params.rollover = 0;
      }

      let formBody = createFormBody(params);

      responseJson = await ApiEngine.post(
        ApiUrl._API_CREATE_BONUS_WALLET_ADJUSTMENT,
        formBody
      );

      _dispatch(
        showMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_MESSAGE_KEY])
        )
      );
    }
    else if (actionId == 2 || actionId == 3) {

      //final validate
      if (actionId == 2 && selectedPublicPromotion == 0) {
        return;
      }
      if (actionId == 3 && selectedExclusivePromo == 0) {
        return;
      }

      let params = {
        memberId: memberId,
        promoId: actionId == 2 ? selectedPublicPromotion : selectedExclusivePromo
      };

      let formBody = createFormBody(params);

      responseJson = await ApiEngine.post(
        ApiUrl._API_ADMIN_CLAIM_PROMO,
        formBody
      );

      _dispatch(
        showMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_MESSAGE_KEY])
        )
      );
    }
    else if (actionId == 4) {

      let params = {
        memberId: memberId,
        promoId: actionId == 2 ? selectedPublicPromotion : selectedExclusivePromo,
        applyTo: applyToOption,
        category: selectedCategories,
        provider: selectedProviders,
        bonus: data.bonusAmount,
        rolloverType: rolloverOption,
        rollover: data.rolloverAmount,
        maxWithdraw: data.maxBonus,
        coinTarget: data.minCoinTaget,
        isDepositRequired: data.isDepositRequired,
        isRolloverIncludeDeposit: data.isRolloverIncludeDeposit
      };
      console.log(params)
      //console.log(formBody)

      let formBody = createFormBody(params);

      responseJson = await ApiEngine.post(
        ApiUrl._API_CLAIM_CUSTOM_PROMOTION,
        formBody
      );

      _dispatch(
        showMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_MESSAGE_KEY])
        )
      );
      setApplyToOption(0)

    }


    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      e.target.reset();
      initFormField();
      setFinalRolloverAmount("");
      setRolloverOption("");
      setRolloverMultiplier("");

      if (props.onComplete != null) {
        props.onComplete();
      }
    }
    getMemberList("", null, { page: 1 });

  };

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberList(search, loadOptions, { page }) {
    let formattedData = [];

    if (search.length >= textLimit) {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE
        }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY]["data"];
        formattedData = data.map((m) => {
          return { label: m.username, value: m.id, balance: (m.bonusWallet + m.tempBonusWallet), bonusWalletRollover: m.bonusWalletRollover };
        });
      }
    }
    return {
      options: formattedData,
      hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
      additional: {
        page: page + 1,
      },
    };

  }




  const _ACTION_OPTIONS = [
    { value: 1, label: "Coin Adjustment" },
    { value: 2, label: "Claim Public Promotion" },
    //{ value: 3, label: "Claim Exclusive Promotion" },
    { value: 4, label: "Claim Custom Promotion" },
  ];

  const ROLLOVER_OPTIONS = [
    { value: 1, label: "Multiplier" },
    { value: 2, label: "Fixed Amount" }
  ];

  const APPLY_TO_OPTION = [
    { value: -1, label: "Provider" },
    { value: 0, label: "Category" },
    //{ value: 3, label: "Provider" }
  ]

  const BONUS_TYPE_OPTION = [
    { value: 1, label: "Fixed Bonus" },
    { value: 2, label: "Latest Deposit x Percentage" },
    //{ value: 3, label: "Provider" }
  ]

  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      minHeight: "34px",
      padding: "0 2px"
    }),
    valueContainer: (base) => ({
      ...base,
      minHeight: "34px",
      padding: "0 2px"
    }),
    indicatorsContainer: (base) => ({
      ...base,
      minHeight: "34px",
      padding: "0 2px"
    }),
    input: (base) => ({
      minHeight: "34px",
      lineHeight: "34px",
      padding: "0 2px"
    }),
  };

  async function checkIsMemberEntitledPromo(promoid) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IS_MEMBER_ENTITLED_PROMO + "?memberId=" + memberId + "&promoId=" + promoid);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsMemberEntitled(true);
      setPromoError("");
    } else {
      setIsMemberEntitled(false);
      setPromoError((responseJson[ApiKey._API_MESSAGE_KEY]));
    }
    _dispatch(setIdle());
  }


  useEffect(() => {
    if (actionId == 2 && memberId != "") {
      checkIsMemberEntitledPromo(selectedPublicPromotion)
    }
    if (actionId == 3 && memberId != "") {
      checkIsMemberEntitledPromo(selectedExclusivePromo)
    }
  }, [memberId]);

  useEffect(() => {
    if (actionId == 4) {
      if (providerList.length == 0) {
        GetProviderList()
      }
      if (productCategoryList.length == 0) {
        GetGameCategoryList()
      }
    }
  }, [actionId]);

  async function GetProviderList() {
    await fetch(ApiUrl._API_GET_PROVIDERS, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const providerList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (provider) {
            providerList.push({ label: provider.name, value: provider.id });
          });
          setProviderList(providerList);
        }
      });
  }

  async function GetGameCategoryList() {
    await fetch(ApiUrl._API_GET_PRODUCT_CATEGORY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          // KT: Why set two times? 
          // setProductCategoryList(responseJson[ApiKey._API_DATA_KEY]);
          const categoryList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (category) {
            if (category.title !== 'All')
              categoryList.push({ label: category.title, value: category.id });
          });
          setProductCategoryList(categoryList);
        }
      });
  }

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    const char = String.fromCharCode(charCode);

    // Allow numbers (0-9), one decimal point, and a minus sign only at the start
    if (!/[0-9]/.test(char) && char !== '.' && char !== '-') {
      e.preventDefault();
    }

    // only one decimal point
    if (char === '.' && e.target.value.includes('.')) {
      e.preventDefault(); // prevent more than one decimal
    }
  };

  return (
    <div>
      <h1 className="page-header">
        <div className="page-title">
          <NavigationButton history={_history} />
          {t("COIN_ADJUSTMENT_EXCLUSIVE_PROMO")}
        </div>
        <div className="page-actions">
          {props.id && (
            <button
              style={{ float: "right" }}
              className="btn btn-inverse"
              onClick={() => {
                props.toggleModal(false);
              }}
            >
              <i className="fa fa-times"></i>
            </button>
          )}
        </div>
      </h1>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("USERNAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{tooltipText}</Tooltip>}
                          show={displayToolTip}
                        >
                          <div className="async-paginate">
                            {
                              <AsyncPaginate
                                name="memberId"
                                placeholder={
                                  selectedMember ? selectedMember.label : ""
                                }
                                isDisabled={!stringIsNullOrEmpty(props.id)}
                                debounceTimeout={250}
                                loadOptions={getMemberList}
                                additional={{
                                  page: 1,
                                }}
                                value={selectedMember}
                                onChange={(e) => {
                                  setValue("memberId", e.value);
                                  setMemberId(e.value);
                                  setSelectedMember(e);
                                  trigger("memberId");
                                }}
                                onInputChange={handleInputChange}
                              />
                            }
                          </div>
                        </OverlayTrigger>
                        {errors.memberId && (
                          <div className="invalid-feedback">
                            {t(errors.memberId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("ACTION")}</b>
                      </label>
                      <div className="col-md-7">
                        <Controller
                          name="remark"
                          control={control}
                          render={({ value, onChange }) => {
                            return (
                              <CreatableSelect
                                options={_ACTION_OPTIONS}
                                isValidNewOption={() => false}
                                placeholder={
                                  _ACTION_OPTIONS.filter(
                                    (option) => option.value == actionId
                                  )[0] !== undefined
                                    ? _ACTION_OPTIONS.filter(
                                      (option) => option.value == actionId
                                    )[0].label
                                    : t("CHOOSE_FROM_LIST_OR_ENTER_MANUALLY")
                                }
                                value={_ACTION_OPTIONS.filter(
                                  (option) => option.value == value
                                )}
                                onChange={(e) => {
                                  setActionId(e.value);
                                }}
                              />
                            );
                          }}
                        />
                        {errors.remark && (
                          <div className="invalid-feedback">
                            {t(errors.remark.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Normal Coin Adjustment*/}
                  {actionId == 1 && (
                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                      <div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              {t("AMOUNT")}
                              <label style={{ color: "blue", paddingLeft: "5px" }}>
                                {selectedMember && `(${selectedMember.balance})`}
                              </label>
                            </b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              step="0.01"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_AMOUNT")}
                              name="amount"
                              ref={register({
                                required: "PLEASE_ENTER_AMOUNT",
                                validate: (value) =>
                                  parseFloat(value) != 0 ||
                                  "PLEASE_DO_NOT_ENTER_ZERO",
                                min: {
                                  value: selectedMember
                                    ? selectedMember.balance * -1
                                    : 0,
                                  message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN",
                                },
                              })}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                            />
                            {errors.amount && (
                              <div className="invalid-feedback">
                                {t(errors.amount.message, {
                                  value: selectedMember
                                    ? selectedMember.balance * -1
                                    : 0,
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              {t("BONUS_ROLLOVER")}
                              <label style={{ color: "blue", paddingLeft: "5px" }}>
                                {selectedMember && `(${selectedMember.bonusWalletRollover})`}
                              </label>
                            </b><br />
                            <small>*{t("OPTIONAL")}</small>
                          </label>
                          <div className="col-md-7 align-content-center">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="radio">
                                  <Controller
                                    name="rolloverType"
                                    control={control}
                                    defaultValue="multi"
                                    render={({ field }) => (
                                      <>
                                        <input
                                          type="radio"
                                          id="rolloverTypeMulti"
                                          value="multi"
                                          onChange={(e) => {
                                            setValue('rolloverType', e.target.value);
                                            setRolloverOption(e.target.value);
                                          }}
                                          checked={rolloverOption === 'multi'}
                                        />
                                        <label htmlFor="rolloverTypeMulti">{t("MULTIPLIER")}</label>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="radio">
                                  <Controller
                                    name="rolloverType"
                                    control={control}
                                    defaultValue="fixed"
                                    render={({ field }) => (
                                      <>
                                        <input
                                          type="radio"
                                          id="rolloverFixed"
                                          value="fixed"
                                          onChange={(e) => {
                                            setValue('rolloverType', e.target.value);
                                            setRolloverOption(e.target.value);
                                          }}
                                          checked={rolloverOption === 'fixed'}
                                        />
                                        <label htmlFor="rolloverFixed">{t("FIXED_AMOUNT")}</label>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {rolloverOption === 'multi' && <div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                          </label>
                          <div className="col-md-7">
                            <div className="row">
                              <div className="col-md-4">
                                <small>{t("ROLLOVER_MULTIPLIER")} (x):</small>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  // defaultValue={rolloverMultiplier}
                                  defaultValue={rolloverMultiplier}
                                  name="rolloverMultiplier"
                                  onFocus={handleFocus}
                                  onKeyPress={handleKeyPress}
                                  onChange={(e) => {
                                    const newValue = e.target.value
                                    if (/^-?\d*\.?\d{0,2}$/.test(newValue)) {
                                      setValue('rolloverMultiplier', newValue);
                                      setRolloverMultiplier(newValue);
                                    }

                                    if (newValue === "") {
                                      setValue('rolloverMultiplier', "");
                                      setRolloverMultiplier("");
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-md-4">
                                <small>{t("FINAL_ROLLOVER_AMOUNT")}:</small>
                                <input
                                  disabled
                                  type="text"
                                  ref={register()}
                                  className="form-control"
                                  defaultValue={rolloverMultiplier}
                                  name="finalRolloverAmount"
                                  value={finalRolloverAmount}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>}

                      {rolloverOption === 'fixed' && <div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                          </label>
                          <div className="col-md-7">
                            <div className="row">
                              <div className="col-md-4">
                                <small>{t("ROLLOVER_FIXED_AMOUNT")} ($):</small>
                                <input
                                  type="text"
                                  className="form-control"
                                  // defaultValue={rolloverFixed}
                                  defaultValue={rolloverFixed}
                                  name="rolloverFixed"
                                  onFocus={handleFocus}
                                  onKeyPress={handleKeyPress}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (/^-?\d*\.?\d{0,2}$/.test(newValue)) {
                                      setValue('rolloverFixed', newValue);
                                      setRolloverFixed(newValue);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>}
                      <div >
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("REMARK")}</b>
                          </label>
                          <div className="col-md-7">
                            <Controller
                              name="remark"
                              control={control}
                              render={({ value, onChange }) => {
                                return (
                                  <CreatableSelect
                                    options={remarkOption}
                                    placeholder={
                                      remarkOption.filter(
                                        (option) => option.value == value
                                      )[0] !== undefined
                                        ? remarkOption.filter(
                                          (option) => option.value == value
                                        )[0].label
                                        : t("CHOOSE_FROM_LIST_OR_ENTER_MANUALLY")
                                    }
                                    value={remarkOption.filter(
                                      (option) => option.value == value
                                    )}
                                    onChange={(e, { action }) => {
                                      switch (action) {
                                        case "select-option":
                                          onChange(e.value);
                                          trigger("remark");
                                          break;
                                        case "create-option":
                                          remarkOption.push(e);
                                          onChange(e.value);
                                          trigger("remark");
                                          break;
                                      }
                                    }}
                                  />
                                );
                              }}
                            />
                            {errors.remark && (
                              <div className="invalid-feedback">
                                {t(errors.remark.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Claim Normal Promotion*/}
                  {actionId == 2 && (
                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                      <div >
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("PUBLIC_PROMOTIONS")}</b>
                          </label>
                          <div className="col-md-7">
                            <Controller
                              name="remark"
                              control={control}
                              render={({ value, onChange }) => {
                                return (
                                  <CreatableSelect
                                    options={publicPromotion}
                                    isValidNewOption={() => false}
                                    placeholder={
                                      selectedPublicPromotion > 0
                                        ? publicPromotion
                                          .find(option => option.options.find(x => x.value === selectedPublicPromotion))
                                          ?.options.find(x => x.value === selectedPublicPromotion).label
                                        : t("PLEASE_SELECT_PROMOTION")
                                    }
                                    value={publicPromotion.find(option => option.value === selectedPublicPromotion) || null}
                                    onChange={(e) => {
                                      if (memberId == "") {
                                        setSelectedPublicPromotion(-99);
                                        setPromoError("PLEASE_SELECT_USERNAME")
                                      } else {
                                        setPromoError("")
                                        checkIsMemberEntitledPromo(e.value);
                                        setSelectedPublicPromotion(e.value);
                                      }
                                    }
                                    }
                                  />
                                );
                              }}
                            />
                            {selectedPublicPromotion != 0 && promoError && (
                              <div className="invalid-feedback">
                                <b>{t("NOT_ENTITLED")}</b> {t(promoError)}
                              </div>
                            )}
                            {selectedPublicPromotion != 0 && promoError == "" && (
                              <b className="text-green">{t("ENTITLED")}</b>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Claim Exclusive Promotion*/}
                  {actionId == 3 && (
                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                      <div >
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("EXCLUSIVE_PROMOTIONS")}</b>
                          </label>
                          <div className="col-md-7">
                            <Controller
                              name="remark"
                              control={control}
                              render={({ value, onChange }) => {
                                return (
                                  <CreatableSelect
                                    isValidNewOption={() => false}
                                    options={exclusivePromotion}
                                    placeholder={
                                      selectedExclusivePromo > 0
                                        ? exclusivePromotion
                                          .find(option => option.options.find(x => x.value === selectedExclusivePromo))
                                          ?.options.find(x => x.value === selectedExclusivePromo).label
                                        : t("PLEASE_SELECT_PROMOTION")
                                    }
                                    value={exclusivePromotion.find(option => option.value === selectedExclusivePromo) || null}
                                    onChange={(e) => {
                                      if (memberId == "") {
                                        setSelectedPublicPromotion(-99);
                                        setPromoError("PLEASE_SELECT_USERNAME")
                                      } else {
                                        setPromoError("")
                                        checkIsMemberEntitledPromo(e.value);
                                        setSelectedExclusivePromo(e.value);
                                      }
                                    }
                                    }
                                  />
                                );
                              }}
                            />
                            {selectedPublicPromotion != 0 && promoError && (
                              <div className="invalid-feedback">
                                <b>{t("NOT_ENTITLED")}</b> {t(promoError)}
                              </div>
                            )}
                            {selectedExclusivePromo != 0 && promoError == "" && (
                              <b className="text-green">{t("ENTITLED")}</b>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/*Custom Promotion*/}
                  {actionId == 4 && (
                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                      <div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              * {t("APPLY_TO")}
                            </b>
                          </label>
                          <div className="col-md-7">
                            <Controller
                              name="rolloverType"
                              control={control}
                              render={({ value, onChange }) => {
                                return (
                                  <CreatableSelect
                                    options={APPLY_TO_OPTION}
                                    isValidNewOption={() => false}
                                    placeholder={
                                      APPLY_TO_OPTION.filter(
                                        (option) => option.value == applyToOption
                                      )[0] !== undefined
                                        ? APPLY_TO_OPTION.filter(
                                          (option) => option.value == applyToOption
                                        )[0].label
                                        : t("PLEASE_SELECT")
                                    }
                                    value={APPLY_TO_OPTION.filter(
                                      (option) => option.value == value
                                    )}
                                    onChange={(e) => {
                                      setApplyToOption(e.value);
                                    }}
                                  />
                                );
                              }}
                            />
                            {errors.rolloverType && (
                              <div className="invalid-feedback">
                                {t(errors.rolloverType.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {applyToOption == 0 && (
                        <div>
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label">
                              <b>
                                * {t("CATEGORIES")}
                              </b>
                            </label>
                            <div className="col-md-7">
                              <Controller
                                control={control}
                                name="categories"
                                rules={{ required: "PLEASE_SELECT_GAME_CATEGORY" }}
                                defaultValue={selectedCategories}
                                render={({ onChange }) => (
                                  <Select
                                    placeholder={t("PLEASE_SELECT_GAME_CATEGORY")}
                                    styles={_OPTION_STYLES}
                                    value={productCategoryList.filter(cat => selectedCategories.includes(cat.value))}
                                    options={productCategoryList}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={(selectedOptions) => {
                                      const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                      onChange(selectedValues);
                                      setSelectedCategories(selectedValues);
                                    }}
                                  />)}
                              />
                              {errors.categories && (
                                <div className="invalid-feedback">
                                  {t(errors.categories.message)}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {applyToOption == -1 && (
                        <div>
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label">
                              <b>
                                * {t("PROVIDERS")}
                              </b>
                            </label>
                            <div className="col-md-7">
                              <Controller
                                control={control}
                                name="providers"
                                rules={{ required: "PLEASE_SELECT_GAME_PROVIDER" }}
                                defaultValue={selectedProviders}
                                render={({ onChange }) => (
                                  <Select
                                    placeholder={t("PLEASE_SELECT_GAME_PROVIDER")}
                                    styles={_OPTION_STYLES}
                                    value={providerList.filter(cat => selectedProviders.includes(cat.value))}
                                    options={providerList}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={(selectedOptions) => {
                                      const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                      onChange(selectedValues);
                                      setSelectedProviders(selectedValues);
                                    }}
                                  />)}
                              />
                              {errors.providers && (
                                <div className="invalid-feedback">
                                  {t(errors.providers.message)}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>* {t("IS_DEPOSIT_REQUIRED")}</b>
                            <br />
                            <small>{t("VALID_PROMO_INFO")}</small>
                          </label>
                          <div className="col-md-7 align-content-center">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="radio">
                                  <Controller
                                    name="isDepositRequired"
                                    control={control}
                                    defaultValue={true}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          type="radio"
                                          id="depositRequiredTrue"
                                          value={true}
                                          onChange={(e) => {
                                            console.log(e.target.value)
                                            setValue('isDepositRequired', e.target.value);
                                            setIsRequiredDeposit(e.target.value);
                                          }}
                                          checked={isRequiredDeposit == "true"}
                                        />
                                        <label htmlFor="depositRequiredTrue">{t("YES")}</label>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="radio">
                                  <Controller
                                    name="isDepositRequired"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          type="radio"
                                          id="depositRequiredFalse"
                                          value={false}
                                          onChange={(e) => {
                                            setValue('isDepositRequired', e.target.value);
                                            setIsRequiredDeposit(e.target.value);
                                          }}
                                          checked={isRequiredDeposit == "false"}
                                        />
                                        <label htmlFor="depositRequiredFalse">{t("NO")}</label>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div >
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>* {isRequiredDeposit == "true" ? t("BONUS_PERCENTAGE") : t("SPECIFIED_BONUS_AMOUNT")}</b>
                            <br />
                            {isRequiredDeposit == "true" &&
                              <small>*{t("BONUS_CALC_VIA_DEPOSIT_INFO")}</small>
                            }
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              step="0.01"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_AMOUNT")}
                              name="bonusAmount"
                              ref={register({
                                required: "PLEASE_ENTER_AMOUNT",
                                validate: (value) =>
                                  parseFloat(value) != 0 ||
                                  "PLEASE_DO_NOT_ENTER_ZERO",
                              })}
                            />
                            {errors.bonusAmount && (
                              <div className="invalid-feedback">
                                {t(errors.bonusAmount.message)}
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              * {t("ROLLOVER")}
                            </b>
                          </label>
                          <div className="col-md-7">
                            <Controller
                              name="rolloverType"
                              control={control}
                              render={({ value, onChange }) => {
                                return (
                                  <CreatableSelect
                                    options={ROLLOVER_OPTIONS}
                                    isValidNewOption={() => false}
                                    placeholder={
                                      ROLLOVER_OPTIONS.filter(
                                        (option) => option.value == rolloverOption
                                      )[0] !== undefined
                                        ? ROLLOVER_OPTIONS.filter(
                                          (option) => option.value == rolloverOption
                                        )[0].label
                                        : t("CHOOSE_FROM_LIST_OR_ENTER_MANUALLY")
                                    }
                                    value={ROLLOVER_OPTIONS.filter(
                                      (option) => option.value == value
                                    )}
                                    onChange={(e) => {
                                      setRolloverOption(e.value);
                                    }}
                                  />
                                );
                              }}
                            />
                            {errors.rolloverType && (
                              <div className="invalid-feedback">
                                {t(errors.rolloverType.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div >
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>* {rolloverOption == 1 ? t("ROLLOVER_MULTIPLIER") : t("ROLLOVER_AMOUNT")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              step="0.01"
                              className="form-control"
                              placeholder={t("PLEASE_INSERT_VALUE")}
                              name="rolloverAmount"
                              ref={register({
                                required: "PLEASE_INSERT_VALUE",
                                validate: (value) =>
                                  parseFloat(value) != 0 ||
                                  "PLEASE_DO_NOT_ENTER_ZERO",
                              })}
                            />
                            {errors.rolloverAmount && (
                              <div className="invalid-feedback">
                                {t(errors.rolloverAmount.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {isRequiredDeposit == "true" &&
                        < div >
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label">
                              <b>* {t("IS_ROLLOVER_INCLUDE_DEPOSIT")}</b><br />
                              <small>{t("ROLLOVER_INCLUDE_PROMO_INFO_YES")}</small><br />
                              <small>{t("ROLLOVER_INCLUDE_PROMO_INFO_NO")}</small>
                            </label>
                            <div className="col-md-7 align-content-center">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="radio">
                                    <Controller
                                      name="isRolloverIncludeDeposit"
                                      control={control}
                                      defaultValue={true}
                                      render={({ field }) => (
                                        <>
                                          <input
                                            type="radio"
                                            id="isRolloverIncludeDepositTrue"
                                            value={true}
                                            onChange={(e) => {
                                              console.log(e.target.value)
                                              setValue('isRolloverIncludeDeposit', e.target.value);
                                              setIsRolloverIncludeDeposit(e.target.value);
                                            }}
                                            checked={isRolloverIncludeDeposit == "true"}
                                          />
                                          <label htmlFor="isRolloverIncludeDepositTrue">{t("YES")}</label>
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="radio">
                                    <Controller
                                      name="isRolloverIncludeDeposit"
                                      control={control}
                                      defaultValue={false}
                                      render={({ field }) => (
                                        <>
                                          <input
                                            type="radio"
                                            id="isRolloverIncludeDepositFalse"
                                            value={false}
                                            onChange={(e) => {
                                              setValue('isRolloverIncludeDeposit', e.target.value);
                                              setIsRolloverIncludeDeposit(e.target.value);
                                            }}
                                            checked={isRolloverIncludeDeposit == "false"}
                                          />
                                          <label htmlFor="isRolloverIncludeDepositFalse">{t("NO")}</label>
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      <div >
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>* {t("MAX_BONUS_WITHDRAWAL")}</b><br />
                            <small>*{t("LEAVE_0_IF_UNLIMITED")}</small>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              step="0.01"
                              className="form-control"
                              placeholder={t("PLEASE_INSERT_AMOUNT")}
                              name="maxBonus"
                              ref={register({
                                required: "PLEASE_INSERT_AMOUNT",
                              })}
                            />
                            {errors.maxBonus && (
                              <div className="invalid-feedback">
                                {t(errors.maxBonus.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div >
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>* {t("MIN_COIN_TARGET")}</b><br />
                            <small>*{t("LEAVE_0_IF_NO_TARGET_REQUIRED")}</small>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              step="0.01"
                              className="form-control"
                              placeholder={t("PLEASE_INSERT_AMOUNT")}
                              name="minCoinTaget"
                              ref={register({
                                required: "PLEASE_INSERT_AMOUNT",
                              })}
                            />
                            {errors.minCoinTaget && (
                              <div className="invalid-feedback">
                                {t(errors.minCoinTaget.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      {memberId != "" && promoError ? (
                        <button type="submit" className={"btn " + (actionId > 1 ? "btn-danger" : "btn-primary")}>
                          {actionId > 1 ? t("CLAIM_ANYWAY") : t("CLAIM")}
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          {actionId == 1 ? t("SAVE") : t("CLAIM")}
                        </button>
                      )}

                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default CoinAdjustment;
