import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import {
	ApiKey,
	ApiUrl,
	Status,
	PaymentType,
	Gender,
	LanguageKey,
	MemberStatus,
	TransferBackMainWallet,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import DateTime from "react-datetime";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";
import Dropzone from "react-dropzone";
import CreatableSelect from "react-select/creatable";

/// <summary>
/// Author : -
/// </summary>
const MemberDetail = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const _dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		errors,
		setValue,
		watch,
		trigger,
		formState,
		control,
	} = useForm();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [memberId, setMemberId] = useState("");

	const [confirmPassword, setConfirmPassword] = useState("");
	const [memberPin, setMemberPin] = useState("");
	const [shareholderPin, setShareholderPin] = useState("");
	const [fullName, setFullName] = useState("");
	const [cnFullName, setCNFullName] = useState("");
	const [phone, setPhone] = useState("");
	const [isPhoneVerified, setIsPhoneVerified] = useState(false);
	const [email, setEmail] = useState("");
	const [gender, setGender] = useState("");
	const [rollOverThreshold, setRollOverThreshold] = useState("");
	const [riskLevel, setRiskLevel] = useState("");
	const [internalTransferLimit, setInternalTransferLimit] = useState(0);
	const [memberDateJoined, setMemberDateJoined] = useState(
		moment().format("YYYY-MM-DD HH:mm:ss")
	);
	const [memberLastLogin, setMemberLastLogin] = useState(
		moment().format("YYYY-MM-DD HH:mm:ss")
	);
	const [shareholderDate, setShareholderDate] = useState(
		moment().format("YYYY-MM-DD HH:mm:ss")
	);
	const [isUpline, setIsUpline] = useState(false);
	const [timeZone, setTimeZone] = useState(0);
	const [memberData, setMemberData] = useState([]);
	const [source, setSource] = useState("");
	const [campaign, setCampaign] = useState("");
	const [address, setAddress] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [referralCode, setReferralCode] = useState("");
	const [parentMemberId, setParentMemberId] = useState("");

	const [paymentType, setPaymentType] = useState("");
	const [vipOption, setVipOption] = useState([]);
	const [status, setStatus] = useState(1);
	const [changeRiskLvl, setChangeRiskLvl] = useState(false);

	const [desktopFile, setDesktopFile] = useState([]);
	const [mobileFile, setMobileFile] = useState([]);
	const [desktopImage, setDesktopImage] = useState();
	const [mobileImage, setMobileImage] = useState();
	const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
	const [isNewMobileImage, setIsNewMobileImage] = useState(false);
	const [isTestAccount, setIsTestAccount] = useState(false);
	const [remarkOption, setRemarkOption] = useState([]);
	const [blockedDate, setBlockedDate] = useState(
		null
	);
	const statusOption = Object.keys(MemberStatus).map((status, index) => {
		return {
			label: t(status.substr(status.indexOf("_") + 1)),
			value: MemberStatus[status],
		};
	});

	const [transferBackToMainWallet, setTransferBackToMainWallet] = useState("");
	const transferOption = Object.keys(TransferBackMainWallet).map(
		(transferBackToMainWallet, index) => {
			return {
				label: t(
					transferBackToMainWallet.substr(
						transferBackToMainWallet.indexOf("_") + 1
					)
				),
				value: TransferBackMainWallet[transferBackToMainWallet],
			};
		}
	);

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = async (data, e) => {
		if (data.password != data.confirmPassword) {
			_dispatch(
				showMessage(false, t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY"))
			);
			return;
		}
		let params = {
			username: data.username,
			password: data.password,
			pinNumber: data.memberPin,
			shareholderPin: data.shareholderPin,
			userFullName: data.fullName,
			userCNFullName: data.cnFullName,
			phoneNumber: data.phone,
			email: data.email,
			paymentModeId: data.paymentType,
			rollOverThreshold: data.rollOverThreshold,
			status: data.status,
			birthDate: data.birthDate,
			vipId: data.vip,
			riskLevel: changeRiskLvl ? data.riskLevel : memberData["riskLevel"],
			internalTransferLimit: data.internalTransferLimit,
			transferBackMainWallet:
				typeof data.transferBackMainWallet !== "undefined"
					? data.transferBackMainWallet
					: 0,
			isNewDesktopFile: isNewDesktopImage,
			isNewMobileFile: isNewMobileImage,
			isTestAccount: isTestAccount,
			remark: data.remark,
		};

		if (!stringIsNullOrEmpty(memberId)) {
			params["id"] = memberId;
		} else if (!stringIsNullOrEmpty(referralCode)) {
			params["referralCode"] = referralCode;
		}

		if (!stringIsNullOrEmpty(parentMemberId)) {
			params["referralMember"] = parentMemberId;
		}

		if (!stringIsNullOrEmpty(desktopFile)) {
			params["desktopFile"] = desktopFile;
		}

		if (stringIsNullOrEmpty(desktopImage)) {
			params["desktopImageRemoved"] = true;
		}

		if (!stringIsNullOrEmpty(mobileFile)) {
			params["mobileFile"] = mobileFile;
		}

		if (stringIsNullOrEmpty(mobileImage)) {
			params["mobileImageRemoved"] = true;
		}

		//let tempIsPhoneVerified = true;
		//if (data.phone !== phone){
		//    params['isPhoneVerify'] = false;
		//    tempIsPhoneVerified = false;
		//}

		let formBody = createMultiPartFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_MEMBER, {
			method: ApiKey._API_POST,
			headers: {
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					//setIsPhoneVerified(tempIsPhoneVerified);
					setMemberId(responseJson[ApiKey._API_DATA_KEY]["id"]);
					props.setParentId(responseJson[ApiKey._API_DATA_KEY]["id"]);
					props.setParentName(data.username);
				}

				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
				init();
			});
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, [props.id, props.parentMemberId]);
	useEffect(()=>{
		if(status < 1) {
			
			if(status == -1){
				fetchRemarks(3);
				setValue("remark",'');
			}else{
				fetchRemarks(2);
				setValue("remark",'');

			}

		
		}
		
	},[status]);

	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		_dispatch(setBusy());

		try {
			var vipList = [];
			var parentReferralCode = "";
			var parentMemberId = props.parentMemberId ?? "";

			var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_VIP);

			if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
				vipList.push({ label: vipLevel.name, value: vipLevel.id });
			});

			setVipOption(vipList);

			if (props.id) {
				responseJson = await ApiEngine.get(
					ApiUrl._API_GET_USER_MEMBER_BY_ID + "?id=" + props.id
				);

				if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
					throw responseJson[ApiKey._API_MESSAGE_KEY];
				}
				let data = responseJson[ApiKey._API_DATA_KEY];
				setIsUpline(data["isUpline"]);
				setTimeZone(data["userTimeZone"]);
				let tempMemberData = data["memberData"];
				setMemberData(tempMemberData);
				setMemberId(tempMemberData["id"]);
				setUsername(tempMemberData["username"]);
				setPassword(tempMemberData["password"]);
				setConfirmPassword(tempMemberData["password"]);
				setMemberPin(tempMemberData["pinNumber"]);
				setShareholderPin(tempMemberData["shareholderPin"]);
				setFullName(tempMemberData["userFullName"]);
				setCNFullName(tempMemberData["userCNFullName"]);
				setPhone(tempMemberData["phoneNumber"]);
				setIsPhoneVerified(tempMemberData["isPhoneVerify"]);
				setValue("phone", tempMemberData["phoneNumber"]);
				setEmail(tempMemberData["email"]);
				setRollOverThreshold(tempMemberData["rollOverThreshold"]);
				setGender(tempMemberData["gender"] ? Gender._MALE : Gender._FEMALE);

				setSource(tempMemberData["utmSource"]);
				setCampaign(tempMemberData["utmCampaign"]);
				setAddress(tempMemberData["address"]);
				setPostalCode(tempMemberData["postalCode"]);
				setState(tempMemberData["state"]);
				setCity(tempMemberData["city"]);
				setPaymentType(tempMemberData["paymentModeId"]);
				setInternalTransferLimit(tempMemberData["internalTransferLimit"]);
				setValue("birthDate", tempMemberData["birthDateString"]);
				setValue("vip", tempMemberData["vipId"]);
				setValue("status", tempMemberData["status"]);
				setStatus(tempMemberData["status"])
				setValue("remark", tempMemberData["remark"]);
				setValue(
					"transferBackMainWallet",
					tempMemberData["transferBackMainWallet"]
				);
				setIsTestAccount(tempMemberData["isTestAccount"]);
				let tempRiskLvl = tempMemberData["riskLevel"];
				if (tempRiskLvl === -99) {
					setRiskLevel(0);
				} else {
					setRiskLevel(tempRiskLvl);
				}
				setMemberDateJoined(
					moment(tempMemberData["createdTime"])
						.add(data["userTimeZone"], "hour")
						.format("YYYY-MM-DD HH:mm:ss")
				);
				setMemberLastLogin(
					moment(tempMemberData["lastLogin"])
						.add(data["userTimeZone"], "hour")
						.format("YYYY-MM-DD HH:mm:ss")
				);
				setShareholderDate(
					moment(tempMemberData["shareHolderDate"]).format(
						"YYYY-MM-DD HH:mm:ss"
					)
				);
				if(tempMemberData["blockUpdatedTime"]){
					setBlockedDate(
						moment(tempMemberData["blockUpdatedTime"])
							.add(data["userTimeZone"], "hour")
							.format("YYYY-MM-DD HH:mm:ss")
					);
				}

				

				if (tempMemberData["parentReferralCodeModel"]) {
					parentReferralCode =
						tempMemberData["parentReferralCodeModel"]["code"];
					parentMemberId =
						tempMemberData["parentReferralCodeModel"]["memberId"];
					setReferralCode(parentReferralCode);
				}

				setDesktopImage(tempMemberData["logo"]);
			} else {
				setChangeRiskLvl(true);
			}

			setParentMemberId(parentMemberId);

			if (!stringIsNullOrEmpty(parentMemberId)) {
				responseJson = await ApiEngine.get(
					ApiUrl._API_GET_MEMBER_REFERRAL_CODES +
						"?ShowDetails=false&MemberId=" +
						parentMemberId
				);

				if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
					throw responseJson[ApiKey._API_MESSAGE_KEY];
				}

				if (
					stringIsNullOrEmpty(parentReferralCode) &&
					responseJson[ApiKey._API_DATA_KEY]["length"] > 0
				) {
					let notExpiredCodes = responseJson[ApiKey._API_DATA_KEY].filter(
						(object) => {
							return moment()
								.startOf("day")
								.isBefore(moment(object["expiryDate"]));
						}
					);

					if (notExpiredCodes.length > 0) {
						setReferralCode(notExpiredCodes[0]["code"]);
					}
				}
			}
		} catch (errorMessage) {
			_dispatch(showMessage(false, t(errorMessage)));
		} finally {
			_dispatch(setIdle());
		}
	}

	/// <summary>
	/// Author -
	/// </summary>
	const onMobileChange = (isValid, newNumber, countryData, fullNumber, ext) => {
		let formattedNumber = fullNumber.split(/[- ]+/).join("").replace("+", "");
		setPhone(formattedNumber);

		setValue("phone", formattedNumber);
		//if (isValid) {
		//    setValue("phone", formattedNumber);
		//}
		//else {
		//    var isEmptyNumber = stringIsNullOrEmpty(formattedNumber);
		//    setValue("phone", isEmptyNumber ? "" : "INVALID_PHONE");
		//}

		trigger("phone");
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const logoDrop = (acceptedFiles, isDesktop) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			if (isDesktop) {
				setDesktopFile(file);
			} else {
				setMobileFile(file);
			}
			const reader = new FileReader();
			reader.onloadend = () => {
				if (isDesktop) {
					setIsNewDesktopImage(true);
					setDesktopImage(reader.result);
				} else {
					setIsNewMobileImage(true);
					setMobileImage(reader.result);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const fetchRemarks = async (typeId) =>{
		let responseJson = await ApiEngine.get(ApiUrl._API_REMARK_BY_TYPE + "?type=" + typeId);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
		  const remarkList = [];
		  responseJson[ApiKey._API_DATA_KEY].forEach(function (remark) {
			remarkList.push({
			  label: remark.description,
			  value: remark.description,
			});
		  });
		  setRemarkOption(remarkList);
		}
	}
	

	return (
		<div>
			<h1
				className="page-header"
				style={{ display: "flex", justifyContent: "space-between" }}
			>
				<div>{memberId && t("EDIT_MEMBER_DETAIL")}</div>
			</h1>
			<Panel>
				<PanelBody>
					<form onSubmit={handleSubmit(submitForm)}>
						<div className="row">
							<div className="col-lg-6">
								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("STATUS")}</b>
									</label>
									<div className="col-lg-9">
										<Controller
											control={control}
											name="status"
											render={({ onChange, value }) => (
												<Select
													options={statusOption}
													value={statusOption.filter(
														(option) => option.value == value
													)}
													onChange={(e) => {
														onChange(e.value);
														setStatus(e.value)
														
													}}
												/>
											)}
											rules={{ required: "PLEASE_SELECT_STATUS" }}
										/>
										{errors.status && (
											<div className="invalid-feedback">
												{t(errors.status.message)}
											</div>
										)}
									</div>
								</div>

								{
									status < 1 && (
										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>{t("REMARK")}</b>
											</label>
											<div className="col-lg-9">
											<Controller
												name="remark"
												control={control}
												render={({ value, onChange }) => {
													return (
													<CreatableSelect
														options={remarkOption}
														placeholder={
														remarkOption.filter(
															(option) => option.value == value
														)[0] !== undefined
															? remarkOption.filter(
																(option) => option.value == value
															)[0].label
															: value ? value:
															t("PLEASE_ENTER_REMARK")
														}
														value={remarkOption.filter(
														(option) => option.value == value
														)}
														onChange={(e, { action }) => {
														switch (action) {
															case "select-option":
															onChange(e.value);
															trigger("remark");
															break;
															case "create-option":
															remarkOption.push(e);
															onChange(e.value);
															trigger("remark");
															break;
														}
														}}
													/>
													);
												}}
												rules={{ required: "PLEASE_SELECT_STATUS" }}
												/>
												{errors.remark && (
													<div className="invalid-feedback">
														{t(errors.remark.message)}
													</div>
												)}
											</div>
										</div>
									)
								}
								

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>* {t("USERNAME")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											ref={register({ required: "PLEASE_ENTER_USERNAME" })}
											name="username"
											className="form-control m-b-5"
											placeholder={t("PLEASE_ENTER_USERNAME")}
											defaultValue={username}
											readOnly={username == "" ? false : true}
											autoComplete="new-password"
										/>
										{errors.username && (
											<div className="invalid-feedback">
												{t(errors.username.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("EN_FULLNAME")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="fullName"
											ref={register}
											className="form-control m-b-5"
											defaultValue={fullName}
											placeholder={t("PLEASE_ENTER_FULL_NAME")}
										/>
										{errors.fullName && (
											<div className="invalid-feedback">
												{t(errors.fullName.message)}
											</div>
										)}
									</div>
								</div>
								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("CN_FULLNAME")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="cnFullName"
											ref={register}
											className="form-control m-b-5"
											defaultValue={cnFullName}
											placeholder={t("PLEASE_ENTER_FULL_NAME")}
										/>
										{errors.cnFullName && (
											<div className="invalid-feedback">
												{t(errors.cnFullName.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>* {t("MOBILE")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											readOnly={isPhoneVerified}
											name="phone"
											ref={register({ required: "PLEASE_ENTER_PHONE_" })}
											className="form-control m-b-5"
											defaultValue={phone}
											placeholder={t("60123456789")}
										/>
										{isPhoneVerified && (
											<i
												className="phone-green-tick fa fa-check"
												style={{ position: "absolute", top: "8px" }}
											></i>
										)}
										{errors.phone && formState.submitCount > 0 && (
											<div className="invalid-feedback">
												{t(errors.phone.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("EMAIL")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="email"
											ref={register({
												pattern: {
													value:
														/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
													message: "INVALID_EMAIL",
												},
											})}
											className="form-control m-b-5"
											defaultValue={email}
											placeholder={t("PLEASE_ENTER_EMAIL")}
										/>
										{errors.email && (
											<div className="invalid-feedback">
												{t(errors.email.message)}
											</div>
										)}
									</div>
								</div>

								<hr style={{ background: "none" }} />
								<hr style={{ background: "none" }} />

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>* {t("PASSWORD")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="password"
											ref={register({
												minLength: {
													value: 6,
													message: "MINIMUM_6_DIGIT_PASSWORD",
												},
											})}
											name="password"
											className="form-control m-b-5"
											placeholder={t("PLEASE_ENTER_PASSWORD")}
											defaultValue={""}
											autoComplete="new-password"
										/>
										{errors.password && (
											<div className="invalid-feedback">
												{t(errors.password.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>* {t("CONFIRM_PASSWORD")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="password"
											name="confirmPassword"
											ref={register({
												minLength: {
													value: 6,
													message: "MINIMUM_6_DIGIT_PASSWORD",
												},
											})}
											className="form-control m-b-5"
											defaultValue={""}
											placeholder={t("PLEASE_ENTER_CONFIRM_PASSWORD")}
										/>
										{errors.confirmPassword && (
											<div className="invalid-feedback">
												{t(errors.confirmPassword.message)}
											</div>
										)}
									</div>
								</div>

								<hr style={{ background: "none" }} />
								<hr style={{ background: "none" }} />

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>* {t("MEMBER_PIN")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="password"
											name="memberPin"
											ref={register({
												maxLength: {
													value: 4,
													message: "MAXIMUM_4_DIGIT_PASSWORD",
												},
											})}
											className="form-control m-b-5"
											defaultValue={""}
											placeholder={t("PLEASE_ENTER_PIN")}
										/>
										{errors.memberPin && (
											<div className="invalid-feedback">
												{t(errors.memberPin.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>* {t("SHAREHOLDER_PIN")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="password"
											name="shareholderPin"
											ref={register({
												minLength: {
													value: 4,
													message: "MINIMUM_4_DIGIT_PASSWORD",
												},
												maxLength: {
													value: 4,
													message: "MAXIMUM_4_DIGIT_PASSWORD",
												},
											})}
											className="form-control m-b-5"
											defaultValue={""}
											placeholder={t("PLEASE_ENTER_PIN")}
										/>
										{errors.shareholderPin && (
											<div className="invalid-feedback">
												{t(errors.shareholderPin.message)}
											</div>
										)}
									</div>
								</div>

								<hr style={{ background: "none" }} />
								<hr style={{ background: "none" }} />

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("DATE_OF_BIRTH")}</b>
									</label>
									<div className="col-lg-9">
										<Controller
											control={control}
											name="birthDate"
											render={({ onChange, value }) => (
												<DateTime
													value={value}
													dateFormat="YYYY-MM-DD"
													closeOnSelect={true}
													onChange={(e) => {
														if (e instanceof moment) {
															onChange(e.format("YYYY-MM-DD"));
														} else {
															onChange("");
														}
													}}
												/>
											)}
											rules={{ required: "PLEASE_ENTER_VALID_DATE" }}
										/>
										{errors.birthDate && (
											<div className="invalid-feedback">
												{t(errors.birthDate.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("GENDER")}</b>
									</label>
									<div className="col-lg-9">
										<select
											className="form-control"
											name="gender"
											ref={register}
											onChange={(e) => setGender(e.target.value)}
											value={gender}
										>
											<option value={Gender._MALE}>{t("MALE")}</option>
											<option value={Gender._FEMALE}>{t("FEMALE")}</option>
										</select>
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("ADDRESS")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="address"
											className="form-control m-b-5"
											ref={register}
											defaultValue={address}
											placeholder={t("PLEASE_ENTER_ADDRESS")}
										/>
										{errors.address && (
											<div className="invalid-feedback">
												{t(errors.address.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("POSTAL_CODE")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="postalCode"
											className="form-control m-b-5"
											ref={register}
											defaultValue={postalCode}
											placeholder={t("PLEASE_ENTER_POSTAL_CODE")}
										/>
										{errors.postalCode && (
											<div className="invalid-feedback">
												{t(errors.postalCode.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("STATE")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="state"
											className="form-control m-b-5"
											ref={register}
											defaultValue={state}
											placeholder={t("PLEASE_ENTER_STATE")}
										/>
										{errors.state && (
											<div className="invalid-feedback">
												{t(errors.state.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("CITY")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="city"
											className="form-control m-b-5"
											ref={register}
											defaultValue={city}
											placeholder={t("PLEASE_ENTER_CITY")}
										/>
										{errors.city && (
											<div className="invalid-feedback">
												{t(errors.city.message)}
											</div>
										)}
									</div>
								</div>

								<hr style={{ background: "none" }} />
								<hr style={{ background: "none" }} />

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("SOURCE")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="source"
											className="form-control m-b-5"
											ref={register}
											defaultValue={source}
											placeholder={t("PLEASE_ENTER_SOURCE")}
										/>
										{errors.source && (
											<div className="invalid-feedback">
												{t(errors.source.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("CAMPAIGN")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="text"
											name="campaign"
											className="form-control m-b-5"
											ref={register}
											defaultValue={campaign}
											placeholder={t("PLEASE_ENTER_CAMPAIGN")}
										/>
										{errors.campaign && (
											<div className="invalid-feedback">
												{t(errors.campaign.message)}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="col-lg-6">
								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("PREVIOUS_LOGIN")}</b>
									</label>
									<div className="col-lg-9">
										{memberLastLogin + " GMT+" + timeZone + ":00"}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("DATE_JOINED")}</b>
									</label>
									<div className="col-lg-9">
										{memberDateJoined + " GMT+" + timeZone + ":00"}
									</div>
								</div>
								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("LAST_BLOCKED_DATE")}</b>
									</label>
									<div className="col-lg-9">
										{blockedDate ? blockedDate + " GMT+" + timeZone + ":00" : null}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("SHAREHOLDER_DATE")}</b>
									</label>
									<div className="col-lg-9">{shareholderDate}</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("TEST_ACCOUNT")}</b>
									</label>
									<div className="col-lg-9">
										<div className="switcher">
											<input
												type="checkbox"
												name="isTestAccount"
												id="isTestAccount"
												onChange={(e) => setIsTestAccount(e.target.checked)}
												value={true}
												checked={isTestAccount}
												ref={register}
											/>
											<label htmlFor="isTestAccount"></label>
										</div>
										{errors.isTestAccount && (
											<div className="invalid-feedback">
												{t(errors.isTestAccount.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("ACCOUNT_MODE")}</b>
									</label>
									<div className="col-lg-9">
										<select
											className="form-control"
											name="paymentType"
											ref={register}
											onChange={(e) => setPaymentType(e.target.value)}
											value={paymentType}
										>
											<option value={PaymentType._POSTPAID}>
												{t("POST_PAID")}
											</option>
											<option value={PaymentType._PREPAID}>
												{t("PRE_PAID")}
											</option>
										</select>
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("ROLLOVER_THRESHOLD")} (%)</b>
									</label>
									<div className="col-lg-9">
										<input
											type="number"
											name="rollOverThreshold"
											ref={register({
												required: "PLEASE_ENTER_ROLLOVER_THRESHOLD",
												min: {
													value: 0,
													message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
												},
												max: {
													value: 100,
													message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
												},
											})}
											className="form-control m-b-5"
											placeholder={t("PLEASE_ENTER_ROLLOVER_THRESHOLD")}
											defaultValue={rollOverThreshold}
										/>
										{errors.rollOverThreshold && (
											<div className="invalid-feedback">
												{t(errors.rollOverThreshold.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("VIP_LEVEL")}</b>
									</label>
									<div className="col-lg-9">
										<Controller
											control={control}
											name="vip"
											render={({ onChange, value }) => (
												<Select
													options={vipOption}
													placeholder={
														vipOption.filter(
															(option) => option.value == value
														)[0] !== undefined
															? vipOption.filter(
																	(option) => option.value == value
															  )[0].label
															: ""
													}
													value={vipOption.filter(
														(option) => option.value == value
													)}
													onChange={(e) => {
														onChange(e.value);
													}}
												/>
											)}
											rules={{ required: "PLEASE_SELECT_VIP_LEVEL" }}
										/>
										{errors.vip && (
											<div className="invalid-feedback">
												{t(errors.vip.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("TRANSFER_BACK_MAIN_WALLET")}</b>
									</label>
									<div className="col-lg-9">
										<Controller
											control={control}
											name="transferBackMainWallet"
											render={({ onChange, value }) => (
												<Select
													options={transferOption}
													value={transferOption.filter(
														(option) => option.value == value
													)}
													onChange={(e) => {
														onChange(e.value);
													}}
												/>
											)}
										/>
										{errors.transferBackMainWallet && (
											<div className="invalid-feedback">
												{t(errors.transferBackMainWallet.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("RISK_LEVEL")}</b>
									</label>
									<div className="col-lg-9">
										<div className="risk-lvl-wrapper">
											<input
												type="number"
												name="riskLevel"
												ref={register({
													required: "PLEASE_ENTER_RISK_LEVEL",
													min: {
														value: 0,
														message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
													},
													max: {
														value: 5,
														message:
															("PLEASE_ENTER_VALUE_NOT_MORE_THAN",
															{ value: 5 }),
													},
												})}
												className="form-control m-b-5"
												placeholder={t("PLEASE_ENTER_RISK_LEVEL")}
												readOnly={!changeRiskLvl}
												defaultValue={riskLevel}
											/>
											<input
												className="risk-lvl-checkbox"
												type="checkbox"
												defaultChecked={changeRiskLvl}
												onChange={() => setChangeRiskLvl(!changeRiskLvl)}
											/>
											<div className="tooltip-text">
												{t("CHECK_TO_CHANGE", { value: t("RISK_LEVEL") })}
											</div>
										</div>
										{errors.riskLevel && (
											<div className="invalid-feedback">
												{t(errors.riskLevel.message)}
											</div>
										)}
									</div>
								</div>

								<div className="form-group d-flex justify-content-center align-items-center">
									<label className="col-lg-3 text-right">
										<b>{t("INTERNAL_TRANSFER_LIMIT")}</b>
									</label>
									<div className="col-lg-9">
										<input
											type="number"
											ref={register()}
											name="internalTransferLimit"
											className="form-control m-b-5"
											value={internalTransferLimit}
											placeholder={internalTransferLimit}
											onChange={(e) => setInternalTransferLimit(e.target.value)}
										/>
									</div>
								</div>

								{isUpline && (
									<div className="form-group d-flex justify-content-center align-items-top">
										<label className="col-lg-3 text-right">
											<b>{t("COMPANY_LOGO")}</b>
										</label>
										<div className="col-lg-9">
											<Dropzone
												accept={"image/*"}
												onDrop={(acceptedFiles) =>
													logoDrop(acceptedFiles, true)
												}
											>
												{({ getRootProps, getInputProps }) => (
													<section>
														<div
															className="dropzone"
															style={{
																minHeight: "200px",
																textAlign: "center",
															}}
															{...getRootProps()}
														>
															<input {...getInputProps()} />
															<h4 style={{ color: "grey" }}>
																{t(
																	"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
																)}
															</h4>
															{!stringIsNullOrEmpty(desktopImage) && (
																<aside className="thumbsContainer">
																	<div className="thumb">
																		<div className="thumbInner">
																			<img
																				src={desktopImage}
																				className="dropzone-img"
																			/>
																		</div>
																	</div>
																</aside>
															)}
														</div>
													</section>
												)}
											</Dropzone>
											<br />
											<button
												type="button"
												className="btn btn-danger"
												onClick={() => {
													setDesktopImage("");
													setDesktopFile();
												}}
											>
												{t("REMOVE_IMAGE")}
											</button>
										</div>
									</div>
								)}
								{!stringIsNullOrEmpty(referralCode) && (
									<div className="form-group d-flex justify-content-center align-items-center">
										<label className="col-lg-3 text-right">
											<b>{t("REFERRAL_MEMBER_CODE")}</b>
										</label>
										<div className="col-lg-9">
											<label>{referralCode}</label>
										</div>
									</div>
								)}
							</div>
						</div>

						<hr />

						<div className="row">
							<div className="col-lg-12 text-right">
								<div className="form-group">
									<button type="submit" className="btn btn-primary">
										{t("SUBMIT")}
									</button>
								</div>
							</div>
						</div>
					</form>
				</PanelBody>
			</Panel>
		</div>
	);
};

export default MemberDetail;
