import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from "react-select/creatable";
import NavigationButton from "../../components/constantComponent/button/NavigationButton.jsx";
import {
	ApiKey,
	ApiUrl,
	TransactionType,
	PanelType,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant.js";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util.js";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import { AsyncPaginate } from "react-select-async-paginate";

import PinInput from "react-pin-input";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

/// <summary>
/// Author : -
/// </summary>
const PointsAdjustment = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _location = useLocation();
	const _dispatch = useDispatch();
	const {
		register,
		unregister,
		handleSubmit,
		errors,
		setValue,
		trigger,
		control,
	} = useForm();
	const [memberOption, setMemberOption] = useState([]);
	const [memberId, setMemberId] = useState("");
	const [fullScreen, setFullScreen] = useState(false);
	const [remarkOption, setRemarkOption] = useState([]);
	const [selectedMember, setSelectedMember] = useState();

	const [showPinEntry, setShowPinEntry] = useState(false);

	const [enteredAdjustmentData, setEnteredAdjustmentData] = useState({});
	const [enteredAdjustmentE, setEnteredAdjustmentE] = useState({});
	const [selectedMemberPoints, setSelectedMemberPoint] = useState();
	const [selectedMemberPointsQuota, setSelectedMemberPointsQuota] = useState(0);
	/// <summary>
	/// Author : -
	/// Editted : -
	/// </summary>
	useEffect(() => {
		register({ name: "memberId" }, { required: "PLEASE_SELECT_MEMBER" });

		/// For The Size Difference of Modal And Page
		if (props.id) {
			setFullScreen(true);
		}

		init();
		initFormField();
	}, []);

	/// <summary>
	/// Author : -
	/// Editted : - (Added Fetch to get IP Address)
	/// </summary>
	async function init() {
		try {
			_dispatch(setBusy());
			let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_REMARK);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				const remarkList = [];
				responseJson[ApiKey._API_DATA_KEY].forEach(function (remark) {
					remarkList.push({
						label: remark.description,
						value: remark.description,
					});
				});

				setRemarkOption(remarkList);
			} else {
				throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
			}
		} catch (error) {
			_dispatch(showMessage(false, error));
		} finally {
			_dispatch(setIdle());
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const initFormField = () => {
		console.log(props.id);
		if (stringIsNullOrEmpty(props.id)) {
			setMemberId("");
			setValue("memberId", "");
			getMemberList("", null, { page: 1 });
			setSelectedMember(null);
			setSelectedMemberPoint("");
		} else {
			setMemberId(props.id);
			setValue("memberId", props.id);
		}
		setValue("remark", "");
	};

	/// <summary>
	/// Author : -
	/// Editted : -
	/// </summary>
	const submitForm = async (data, e) => {
		let params = {
			memberId: memberId,
			points: data.points,
			panel: PanelType._BACKOFFICE,
			remark: data.remark,
			pointQuota: data.pointQuota
		};

		console.log(params);
		let formBody = createFormBody(params);

		let responseJson = await ApiEngine.post(
			ApiUrl._API_UPDATE_POINTS_ADJUSTMENT,
			formBody
		);

		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			init();
			initFormField();
			e.target.reset();
			if (props.onComplete != null) {
				props.onComplete();
			}
		}
		
		setShowPinEntry(false);
	};

	/// <summary>
	/// Author : Wong
	/// </summary>
	async function getMemberList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let formattedData = data.map((m) => {
				console.log(m.memberPoints);
				return { label: m.username, value: m.id, points: m.memberPoints, pointQuota : m.pointQuota };
			});

			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	async function onPinSubmit(data, e) {
		// verify pin
		let params = { transactionPinNumber: data };
		let formBody = createFormBody(params);
		let responseJson = await ApiEngine.post(
			ApiUrl._API_VERIFY_USER_TRANSACTION_PIN_NUMBER,
			formBody
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			submitForm(enteredAdjustmentData, enteredAdjustmentE);
		} else {
			_dispatch(showMessage(false, t("INCORRECT") + " " + t("PIN_NUMBER")));
			setShowPinEntry(false);
		}
	}

	async function checkPinValue(data, e) {
		// check if pin has 4 digits (valid pin)
		// if true then submit pin
		if (data.length == 4) {
			onPinSubmit(data);
		}
	}

	async function onSubmit(data, e) {
		setEnteredAdjustmentData(data);
		setEnteredAdjustmentE(e);

		setShowPinEntry(true);
	}

	return (
		<div>
			<h1 className="page-header">
				{t("ADJUSTMENT")}{" "}
				{props.id ? (
					<button
						style={{ float: "right" }}
						className="btn btn-inverse"
						onClick={() => {
							props.toggleModal(false);
						}}
					>
						<i className="fa fa-times"></i>
					</button>
				) : (
					<NavigationButton history={_history} />
				)}
			</h1>
			<div className="row">
				<div className="col-xl-12">
					<Panel>
						<PanelBody>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row">
									<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
										<div className="form-group row m-b-15">
											<label className="col-md-4 col-form-label">
												<b>{t("USERNAME")}</b>
											</label>
											<div className="col-md-7">
												<AsyncPaginate
													name="memberId"
													placeholder={
														selectedMember ? selectedMember.label : ""
													}
													isDisabled={!stringIsNullOrEmpty(props.id)}
													debounceTimeout={250}
													loadOptions={getMemberList}
													additional={{
														page: 1,
													}}
													value={selectedMember}
													onChange={(e) => {
														console.log(e);
														setValue("memberId", e.value);
														setMemberId(e.value);
														setSelectedMember(e);
														setSelectedMemberPoint(e.points);
														setSelectedMemberPointsQuota(e.pointQuota);
														trigger("memberId");
													}}
												/>
												{errors.memberId && (
													<div className="invalid-feedback">
														{t(errors.memberId.message)}
													</div>
												)}
											</div>
										</div>
									</div>
									<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
										<div className="form-group row m-b-15">
											<label className="col-md-4 col-form-label">
												<b>
													{t("POINT_QUOTA")}
													<label style={{ color: "blue", paddingLeft: "5px" }}>
														{selectedMember && `(${selectedMemberPointsQuota})`}
													</label>
												</b>
											</label>
											<div className="col-md-7">
												<input
													type="number"
													step="0.01"
													className="form-control"
													placeholder={t("PLEASE_ENTER_POINT_QUOTA")}
													name="pointQuota"
													ref={register({
															required: "PLEASE_ENTER_POINT_QUOTA",
															//validate: (value) =>
															//	parseFloat(value) != 0 ||
															//	"PLEASE_DO_NOT_ENTER_ZERO",
															//min: {
															//	value: selectedMember
															//		? selectedMember.points * -1
															//		: 0,
															//	message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN",
															//},
														})}										/>
												{errors.pointQuota && (
													<div className="invalid-feedback">
														{t(errors.points.message, {
															value: selectedMember
																? selectedMember.pointQuota * -1
																: 0,
														})}
													</div>
												)}
											</div>
										</div>
									</div>
									<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
										<div className="form-group row m-b-15">
											<label className="col-md-4 col-form-label">
												<b>
													{t("POINTS")}
													<label style={{ color: "blue", paddingLeft: "5px" }}>
														{selectedMember && `(${selectedMemberPoints})`}
													</label>
												</b>
											</label>
											<div className="col-md-7">
												<input
													type="number"
													step="0.01"
													className="form-control"
													placeholder={t("PLEASE_ENTER_POINTS")}
													name="points"
													ref={register({
														required: "PLEASE_ENTER_POINTS",
														//validate: (value) =>
														//	parseFloat(value) != 0 ||
														//	"PLEASE_DO_NOT_ENTER_ZERO",
														//min: {
														//	value: selectedMember
														//		? selectedMember.points * -1
														//		: 0,
														//	message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN",
														//},
													})}
												/>
												{errors.points && (
													<div className="invalid-feedback">
														{t(errors.points.message, {
															value: selectedMember
																? selectedMember.points * -1
																: 0,
														})}
													</div>
												)}
											</div>
										</div>
									</div>
									<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
										<div className="form-group row m-b-15">
											<label className="col-md-4 col-form-label">
												<b>{t("REMARK")}</b>
											</label>
											<div className="col-md-7">
												<Controller
													name="remark"
													control={control}
													render={({ value, onChange }) => {
														return (
															<CreatableSelect
																options={remarkOption}
																placeholder={
																	remarkOption.filter(
																		(option) => option.value == value
																	)[0] !== undefined
																		? remarkOption.filter(
																				(option) => option.value == value
																		  )[0].label
																		: t("PLEASE_ENTER_REMARK")
																}
																value={remarkOption.filter(
																	(option) => option.value == value
																)}
																onChange={(e, { action }) => {
																	switch (action) {
																		case "select-option":
																			onChange(e.value);
																			trigger("remark");
																			break;
																		case "create-option":
																			remarkOption.push(e);
																			onChange(e.value);
																			trigger("remark");
																			break;
																	}
																}}
															/>
														);
													}}
												/>
												{errors.remark && (
													<div className="invalid-feedback">
														{t(errors.remark.message)}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<hr />
								<div className="row">
									<div className="col-lg-3">
										<div className="form-group">
											<button type="submit" className="btn btn-primary">
												{t("SUBMIT")}
											</button>
										</div>
									</div>
								</div>
							</form>
						</PanelBody>
					</Panel>
				</div>
			</div>
			<Modal
				fade={false}
				contentClassName="modal-brand modal-bottom modal-numpad"
				isOpen={showPinEntry}
				centered
			>
				<ModalBody>
					<form onSubmit={handleSubmit(onPinSubmit)}>
						<div className="display-wrapper">
							<button
								type="button"
								style={{
									alignSelf: "flex-end",
									backgroundColor: "transparent",
									border: "none",
									marginRight: "15px",
									fontSize: "2rem",
									color: "#002e6c",
									marginTop: "-15px",
									float: "right",
									fontWeight: "bold",
								}}
								onClick={() => {
									setShowPinEntry(false);
								}}
								data-dismiss="modal"
								aria-hidden="true"
							>
								&times;
							</button>
							<strong
								className="reload-title text-yellow mb-3 d-block"
								style={{ fontSize: "20px" }}
							>
								{t("ENTER_YOUR_PIN")}
							</strong>
							<PinInput
								length={4}
								initialValue=""
								secret={true}
								focus={true}
								autoSelect={true}
								type="numeric"
								onChange={(value, index) => {
									checkPinValue(value, index);
								}}
								inputMode="numeric"
								inputStyle={{
									border: "0",
									margin: "0px 10px",
									width: "40px",
									height: "40px",
									backgroundColor: "transparent",
									borderBottom: "2px solid #002e6c",
								}}
								inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
								regexCriteria={/^[ 0-9_@./#&+-]*$/}
							/>
							{/*<button type="submit" style={{ position: "relative", marginTop: "30px", width: "250px" }} className="btn btn-block btn-brand-gradient btn-sticky-bottom">{t("SUBMIT_TRANSACTION")}</button>*/}
							{/*<div className="invalid-feedback" style={{ fontSize: "12px", marginTop: "1rem" }}>{invalidPin && t("PIN_DOES_NOT_MATCH")}{!invalidPin && <span>&nbsp;</span>}</div>*/}
						</div>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default PointsAdjustment;
