import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	ApiKey,
	ApiUrl,
	Status,
	LanguageKey,
	ContentTypeString,
	_SYNC_INTERVAL_PATTERN,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import ApiEngine from "../../util/ApiEngine.js";
import Select from "react-select";
import ManageDCItemContentLanguage from "./ManageDCItemContentLanguage";
import classnames from "classnames";

/// <summary>
/// Author : -
/// </summary>

const DCItemDetail = (props) => {
	const _DETAIL_TAB = "1";
	const _LANGUAGE_TAB = "2";
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _location = useLocation();
	const _dispatch = useDispatch();

	const { register, handleSubmit, errors, setValue, watch, unregister } =
		useForm();
	const [itemId, setItemId] = useState("");
	const [itemName, setItemName] = useState("");
	const [pointsNeeded, setPointsNeeded] = useState(0);
	const [stock, setStock] = useState(0);
	const [limitPerMember, setLimitPerMember] = useState(0);
	const [status, setStatus] = useState(true);
	const [isNewImage, setIsNewImage] = useState(false);
	const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
	const [image, setImage] = useState();
	const [imageFile, setImageFile] = useState([]);
	const [desktopImage, setDesktopImage] = useState();
	const [desktopFile, setDesktopFile] = useState([]);
	const [productType, setProductType] = useState("Product");
	const [voucherValue, setVoucherValue] = useState(0.0);
	const [rollOver, setRollOver] = useState(0);
	const _PRODUCT_TYPE = [
		{ value: "Product", label: "Product" },
		{ value: "Voucher", label: "Voucher" },
		{ value: "Credit", label: "Credit" },
	];

	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};
	/// <summary>
	/// Author : -
	/// Used to set image
	/// </summary>
	const imageDrop = (acceptedFiles) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			setImageFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setImage(reader.result);
				setIsNewImage(true);
			};
			reader.readAsDataURL(file);
		}
	};

	/// <summary>
	/// Author : -
	/// Used to set image for desktop
	/// </summary>
	const desktopImageDrop = (acceptedFiles) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			setDesktopFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setDesktopImage(reader.result);
				setIsNewDesktopImage(true);
			};
			reader.readAsDataURL(file);
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = async (data, e) => {
		let params = {
			id: itemId,
			itemName: data.itemName,
			status: data.status,
			pointsNeeded: data.pointsNeeded,
			stock: data.stock,
			limitPerMember: data.limitPerMember,
			productType: productType,
			value: data.voucherValue,
			rollOver: stringIsNullOrEmpty(data.rollOver) ? 0 : data.rollOver,
			isNewImage: isNewImage,
			isNewDesktopImage: isNewDesktopImage,
		};

		if (!stringIsNullOrEmpty(imageFile)) {
			params["image"] = imageFile;
		}

		if (!stringIsNullOrEmpty(desktopFile)) {
			params["desktopImage"] = desktopFile;
		}

		if (stringIsNullOrEmpty(image)) {
			params["imageRemoved"] = true;
		}

		if (stringIsNullOrEmpty(desktopImage)) {
			params["desktopImageRemoved"] = true;
		}
		let formBody = createMultiPartFormBody(params);

		let responseJson = await ApiEngine.post(
			ApiUrl._API_CREATE_OR_UPDATE_DC_ITEM,
			formBody
		);

		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setIsNewImage(false);
			setIsNewDesktopImage(false);
			//setItemId(responseJson[ApiKey._API_DATA_KEY]["id"]);
			init();
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		if (_location.state) {
			var apiUrl = ApiUrl._API_GET_DC_ITEM_BY_ID;
			apiUrl += "?id=" + _location.state.id;
			let responseJson = await ApiEngine.get(apiUrl);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setItemId(responseJson[ApiKey._API_DATA_KEY]["id"]);
				setItemName(responseJson[ApiKey._API_DATA_KEY]["name"]);
				setPointsNeeded(responseJson[ApiKey._API_DATA_KEY]["pointsNeeded"]);
				setStock(responseJson[ApiKey._API_DATA_KEY]["stock"]);
				setLimitPerMember(responseJson[ApiKey._API_DATA_KEY]["limitPerMember"]);
				setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
				setProductType(responseJson[ApiKey._API_DATA_KEY]["productType"]);

				if(!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["value"]))
				setVoucherValue(responseJson[ApiKey._API_DATA_KEY]["value"].toFixed(2));

				setRollOver(responseJson[ApiKey._API_DATA_KEY]["rollOver"]);
				if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["image"])) {
					setImage(responseJson[ApiKey._API_DATA_KEY]["image"]);
				}

				console.log(responseJson[ApiKey._API_DATA_KEY]["image"]);
				if (
					!stringIsNullOrEmpty(
						responseJson[ApiKey._API_DATA_KEY]["desktopImage"]
					)
				) {
					setDesktopImage(responseJson[ApiKey._API_DATA_KEY]["desktopImage"]);
				}
			}
		}
	}

	useEffect(() => {
		init();
	}, []);

	console.log(image);
	return (
		<div>
			<h1 className="page-header">
				{itemId ? t("EDIT_REWARDS_POINTS") : t("ADD_NEW_REWARDS_POINTS")}
				<NavigationButton history={_history} />
			</h1>
			{itemId && (
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _DETAIL_TAB })}
							onClick={() => {
								toggle(_DETAIL_TAB);
							}}
						>
							{t("DETAIL")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _LANGUAGE_TAB })}
							onClick={() => {
								toggle(_LANGUAGE_TAB);
							}}
						>
							{t("LANGUAGE")}
						</NavLink>
					</NavItem>
				</Nav>
			)}
			<TabContent activeTab={activeTab}>
				<TabPane tabId={_DETAIL_TAB}>
					<div className="row">
						<div className="col-xl-12">
							<Panel>
								<PanelBody>
									<form onSubmit={handleSubmit(submitForm)}>
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<label>
														<b>{t("ITEM_NAME")}</b>
													</label>
													<input
														type="text"
														name="itemName"
														className="form-control m-b-5"
														placeholder={t("PLEASE_ENTER_ITEM_NAME")}
														ref={register({
															required: "PLEASE_ENTER_ITEM_NAME",
														})}
														defaultValue={itemName}
													/>
													{errors.itemName && (
														<div className="invalid-feedback">
															{t(errors.itemName.message)}
														</div>
													)}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="form-group">
													<label>
														<b>{t("POINTS_NEEDED")}</b>
													</label>
													<input
														type="number"
														name="pointsNeeded"
														className="form-control m-b-5"
														placeholder={t("PLEASE_ENTER_POINTS_NEEDED")}
														ref={register({
															required: "PLEASE_ENTER_POINTS_NEEDED",
															min: {
																value: 0,
																message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
															},
														})}
														value={pointsNeeded}
														onChange={(e) => setPointsNeeded(e.target.value)}
													/>
													{errors.pointsNeeded && (
														<div className="invalid-feedback">
															{t(errors.pointsNeeded.message)}
														</div>
													)}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="form-group">
													<label>
														<b>{t("STOCK")}</b>
													</label>
													<input
														type="number"
														className="form-control m-b-5"
														name="stock"
														placeholder={t("PLEASE_ENTER_STOCK")}
														ref={register({
															required: "PLEASE_ENTER_STOCK",
															min: {
																value: 0,
																message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
															},
														})}
														value={stock}
														onChange={(e) => setStock(e.target.value)}
													/>
													{errors.stock && (
														<div className="invalid-feedback">
															{t(errors.stock.message)}
														</div>
													)}
												</div>
											</div>
											<div className="col-lg-3">
												<div className="form-group">
													<label>
														<b>{t("LIMIT_PER_MEMBER")}</b>
													</label>
													<input
														type="number"
														className="form-control m-b-5"
														name="limitPerMember"
														placeholder={t("PLEASE_ENTER_LIMIT_PER_MEMBER")}
														ref={register({
															required: "PLEASE_ENTER_LIMIT_PER_MEMBER",
															min: {
																value: 0,
																message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
															},
														})}
														value={limitPerMember}
														onChange={(e) => setLimitPerMember(e.target.value)}
													/>
													{errors.limitPerMember && (
														<div className="invalid-feedback">
															{t(errors.limitPerMember.message)}
														</div>
													)}
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<label>
														<b>{t("PRODUCT_TYPE")}</b>
													</label>
													<div className="m-b-5">
														<Select
															id="productType"
															name="productType"
															options={_PRODUCT_TYPE}
															value={_PRODUCT_TYPE.filter(
																(option) => option.value == productType
															)}
															onChange={(e) => {
																setProductType(e.value);
															}}
														/>
														<label htmlFor="productType"></label>
													</div>
												</div>
											</div>
											{(productType === "Credit" ||
												productType === "Voucher") && (
												<div className="col-lg-3">
													<div className="form-group">
														<label>
															<b>{t("VALUE")}</b>
														</label>
														<input
															type="number"
															name="voucherValue"
															className="form-control m-b-5"
															placeholder={t("PLEASE_ENTER_VOUCHER_VALUE")}
															ref={register({
																required: "PLEASE_ENTER_VOUCHER_VALUE",
																min: {
																	value: 0,
																	message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
																},
															})}
															value={voucherValue}
															onChange={(e) => setVoucherValue(e.target.value)}
															pattern="[0-9]+.[0-9]"
															step="0.01"
														/>
														{errors.voucherValue && (
															<div className="invalid-feedback">
																{t(errors.voucherValue.message)}
															</div>
														)}
													</div>
												</div>
											)}

											{productType === "Credit" && (
												<div className="col-lg-3">
													<div className="form-group">
														<label>
															<b>{t("ROLLOVER")}</b>
														</label>
														<input
															type="number"
															name="rollOver"
															className="form-control m-b-5"
															placeholder={t("PLEASE_ENTER_ROLLOVER_VALUE")}
															ref={register({
																required: "PLEASE_ENTER_ROLLOVER_VALUE",
																min: {
																	value: 0,
																	message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
																},
															})}
															value={rollOver}
															onChange={(e) => setRollOver(e.target.value)}
														/>
														{errors.rollOver && (
															<div className="invalid-feedback">
																{t(errors.rollOver.message)}
															</div>
														)}
													</div>
												</div>
											)}
											<div className="col-lg-3">
												<div className="form-group">
													<label>
														<b>{t("STATUS")}</b>
													</label>
													<div className="m-b-5">
														<div className="switcher">
															<input
																type="checkbox"
																name="status"
																id="status"
																onChange={(e) => setStatus(e.target.checked)}
																value={true}
																checked={status}
																ref={register}
															/>
															<label htmlFor="status"></label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-6">
												<div className="form-group">
													<label>
														<b>{t("IMAGE")}</b>
													</label>
													<Dropzone
														accept={"image/*"}
														onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
													>
														{({ getRootProps, getInputProps }) => (
															<section>
																<div
																	className="dropzone"
																	style={{
																		minHeight: "200px",
																		textAlign: "center",
																	}}
																	{...getRootProps()}
																>
																	<input {...getInputProps()} />
																	<h4 style={{ color: "grey" }}>
																		{t(
																			"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
																		)}
																	</h4>
																	{!stringIsNullOrEmpty(image) && (
																		<aside className="thumbsContainer">
																			<div className="thumb">
																				<div className="thumbInner">
																					<img
																						src={image}
																						className="dropzone-img"
																					/>
																				</div>
																			</div>
																		</aside>
																	)}
																</div>
															</section>
														)}
													</Dropzone>
													<br />
													<button
														type="button"
														className="btn btn-danger"
														onClick={() => {
															setImage("");
															setImageFile();
														}}
													>
														{t("REMOVE_IMAGE")}
													</button>
												</div>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<button type="submit" className="btn btn-primary">
														{t("SUBMIT")}
													</button>
												</div>
											</div>
										</div>
									</form>
								</PanelBody>
							</Panel>
						</div>
					</div>
				</TabPane>
				<TabPane tabId={_LANGUAGE_TAB}>
					{itemId && (
						<div className="row">
							<div className="col-lg-12">
								<ManageDCItemContentLanguage
									id={itemId}
									type={ContentTypeString._DCITEM_CONTENT}
								/>
							</div>
						</div>
					)}
				</TabPane>
			</TabContent>
		</div>
	);
};

export default DCItemDetail;
