import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { navigateTo, numberWithCurrencyFormat } from "../../util/Util";
import {
	ApiKey,
	ApiUrl,
	AccessRight,
	WebUrl,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
	Role,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
//import CustomButtonWithIcon from "../../components/pksoft/button";
//import FilterInput from "../../components/pksoft/filter-input";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

const ManageMemberRebate = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	var _username = useSelector(
		(state) => state["authState"]["userData"]["username"]
	);

	const [tableData, setTableData] = useState([]);
	const [selectedDate, setSelectedDate] = useState(
		moment().subtract(1, "days").format("YYYY-MM-DD")
	);
	const [calBy, setCalBy] = useState("Valid Turnover");
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [release, setRelease] = useState("");
	const [initFlag, setInitFlag] = useState(false);
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);

	let _tableColumns = [
		{
			id: "action",
			Header: "",
			Cell: ({ row }) =>
				checkIfPermissionExist(
					AccessRight._MANAGE_MEMBER_WEEKLY_RESCUE,
					true
				) &&
				(row.original.generated == false ? (
					<div className="btn-group m-r-5 m-b-5">
						<input
							type="checkbox"
							value={
								row.original.memberId +
								"," +
								row.original.selfCommAmount +
								"," +
								row.original.downlineCommAmount +
								"," +
								row.original.date
							}
							checked={selectedCheckboxes.includes(
								row.original.memberId +
								"," +
								row.original.selfCommAmount +
								"," +
								row.original.downlineCommAmount +
								"," +
								row.original.date
							)}
							onChange={(e) => handleCheckboxChange(e)}
						/>
					</div>
				) : (
					<div className="btn-group m-r-5 m-b-5">
						<span className="text-approved font-bold">{t("RELEASED")}</span>
					</div>
				)),
			disableSortBy: true,
			disableFilters: true,
			width: 50,
			style: { overflow: "visible" },
			Footer: () => {
				return (
					<span>
						<b>{t("TOTAL")}: </b>
					</span>
				);
			},
		},
		{
			Header: t("USERNAME"),
			accessor: "usernmae",
			minWidth: 100,
			Cell: ({ row }) => {
				return <span>{row.original.username}</span>;
			},
		},
		{
			Header: calBy == "Win Lose" ? "Self Win Lose" : t("SELF_VT"),
			accessor: "selfVTurnover",
			minWidth: 100,
			Footer: ({ page }) => {
				const creditSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.selfVTurnover) {
						return sum + parseFloat(currentValue.original.selfVTurnover);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b>
					</span>
				);
			},
		},
		{
			Header: t("SELF_COMM"),
			accessor: "selfCommAmount",
			minWidth: 100,
			Footer: ({ page }) => {
				const creditSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.selfCommAmount) {
						return sum + parseFloat(currentValue.original.selfCommAmount);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b>
					</span>
				);
			},
		},
		{
			Header: calBy == "Win Lose" ? "Downline Win Lose" : t("DL_VT"),
			accessor: "downlineVTurnover",
			minWidth: 100,
			Cell: ({ row }) => {
				return (
					<>
						{numberWithCurrencyFormat(
							parseFloat(row.original.downlineVTurnover),
							3
						)}
					</>
				);
			},
		},
		{
			Header: t("DL_COMM"),
			accessor: "downlineCommAmount",
			minWidth: 100,
			Footer: ({ page }) => {
				const creditSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.downlineCommAmount) {
						return sum + parseFloat(currentValue.original.downlineCommAmount);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b>
					</span>
				);
			},
		},
		//{
		//  Header: "TotalVTurnover",
		//  accessor: "TotalVTurnover",
		//  minWidth: 100,
		//  Cell: ({ row }) => {
		//    return <>{numberWithCurrencyFormat((parseFloat(row.original.selfVTurnover) + parseFloat(row.original.downlineVTurnover)))}</>
		//  },
		//  Footer: ({ page }) => {
		//    const creditSum = page.reduce((sum, currentValue) => {
		//      if (currentValue.original.selfVTurnover > 0 || currentValue.original.downlineVTurnover > 0 ) {
		//        return sum + parseFloat(currentValue.original.selfVTurnover) + parseFloat(currentValue.original.downlineVTurnover);
		//      }
		//      else {
		//        return sum;
		//      }
		//    }, 0);
		//    return <span><b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b></span>
		//  }
		//},

		{
			Header: calBy == "Win Lose" ? "Total Win Lose" : t("TOTAL_VT"),
			accessor: "TotalVTurnover",
			minWidth: 100,
			Cell: ({ row }) => {
				return (
					<>
						{numberWithCurrencyFormat(
							parseFloat(row.original.selfVTurnover) +
							parseFloat(row.original.downlineVTurnover),
							3
						)}
					</>
				);
			},
		},

		{
			Header: t("TOTAL_COMM"),
			accessor: "TotalCommission",
			minWidth: 100,
			Cell: ({ row }) => {
				return (
					<>
						{numberWithCurrencyFormat(
							parseFloat(row.original.selfCommAmount) +
							parseFloat(row.original.downlineCommAmount),
							3
						)}
					</>
				);
			},
			Footer: ({ page }) => {
				const totalVT = page.reduce((sum, currentValue) => {
					if (Math.abs(currentValue.original.selfVTurnover) > 0) {
						return (
							sum + parseFloat(Math.abs(currentValue.original.selfVTurnover))
						);
					} else {
						return sum;
					}
				}, 0);

				const creditSum = page.reduce((sum, currentValue) => {
					if (
						currentValue.original.selfCommAmount > 0 ||
						currentValue.original.downlineCommAmount > 0
					) {
						return (
							sum +
							parseFloat(currentValue.original.selfCommAmount) +
							parseFloat(currentValue.original.downlineCommAmount)
						);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>
							{numberWithCurrencyFormat(parseFloat(creditSum), 3)} /{" "}
							{creditSum > 0
								? parseFloat((creditSum / Math.abs(totalVT)) * 100).toFixed(2)
								: 0}
							%
						</b>
					</span>
				);
			},
		},
	];

	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
	const handleCheckboxChange = (e) => {
		const { value } = e.target;
		setSelectedCheckboxes((prevSelected) => {
			if (prevSelected.includes(value)) {
				return prevSelected.filter((id) => id !== value);
			} else {
				return [...prevSelected, value];
			}
		});
	};

	useEffect(() => {
		_dispatch(setBusy());
		const queryParams = new URLSearchParams(window.location.search);
		const date = queryParams.get("date") || "";
		const calculateby = queryParams.get("calculateby") || "";

		if (!stringIsNullOrEmpty(date)) {
			setSelectedDate(date);
		}

		if (!stringIsNullOrEmpty(calculateby)) {
			setCalBy(calculateby);
		}
		setInitFlag(true);
		// getRebateMemberList(calculateby);
		_dispatch(setIdle());
	}, []);

	useEffect(() => {
		if (initFlag) {
			const queryParams = new URLSearchParams(window.location.search);
			const calculateby = queryParams.get("calculateby") || "";
			if (!stringIsNullOrEmpty(calculateby)) {
				getRebateMemberList(calculateby);
			} else {
				getRebateMemberList(calBy);
			}
		}
	}, [initFlag]);

	const updateSearchParams = (calculateBy) => {
		const params = new URLSearchParams(window.location.search);

		params.set("date", selectedDate);
		params.set("calculateby", calculateBy);

		_history.replace({ search: params.toString() });
	};

	async function getRebateMemberList(calculateBy) {
		var calcBy = calculateBy;
		_dispatch(setBusy());
		updateSearchParams(calcBy);

		fetch(
			ApiUrl._API_GET_MEMBER_COMMISSION_LIST +
			"?date=" +
			selectedDate +
			"" +
			"&type=" +
			calcBy,
			{
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setTableData([]);
					if (responseJson[ApiKey._API_DATA_KEY] != null)
						setTableData(responseJson[ApiKey._API_DATA_KEY]);
					else setTableData([]);
				}

				_dispatch(setIdle());
			});
	}

	async function Claim(idList, select) {
		console.log(idList);
		idList.forEach(function (option) {
			let params = [];
			if (select != "all") {
				var list = option.split(",");
				params = {
					selectedMemberId: list[0],
					selfComm: list[1],
					dlComm: list[2],
					date: list[3],
					type: calBy,
				};
			} else {
				params = {
					selectedMemberId: option.memberId,
					selfComm: option.selfCommAmount,
					dlComm: option.downlineCommAmount,
					date: option.date,
					type: calBy,
				};
			}

			console.log(params);

			let formBody = createFormBody(params);
			_dispatch(setBusy());
			fetch(ApiUrl._API_GENERATE_MLM_COMMISSION, {
				method: ApiKey._API_POST,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
				body: formBody,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					getRebateMemberList();
					setSelectedCheckboxes([]);
					_dispatch(setIdle());
					_dispatch(
						showMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							t(responseJson[ApiKey._API_MESSAGE_KEY])
						)
					);
				});
		});
	}

	const handleEvent = (event, picker) => {
		setSelectedDate(moment(picker.startDate).format("YYYY-MM-DD"));
	};

	const _OPTION_STYLES = {
		control: (base) => ({
			...base,
			"min-height": "34px",
			height: "34px",
		}),
		valueContainer: (base) => ({
			...base,
			height: "34px",
			"min-height": "34px",
		}),
		indicatorsContainer: (base) => ({
			...base,
			height: "34px",
			"min-height": "34px",
		}),
		input: (base) => ({
			height: "34px",
			"min-height": "34px",
		}),
	};

	const _COMM_CALC_BY = [
		//{ value: "Win Lose", label: "Win Lose" },
		{ value: "Valid Turnover", label: "Valid Turnover" },
	];

	return (
		<div>
			{showConfirmation && (
				<SweetAlert
					warning
					showCancel
					confirmBtnText={t("CONFIRM")}
					cancelBtnText={t("CANCEL")}
					confirmBtnBsStyle="danger"
					title={"Rebate will be calculate by " + calBy + ". Confirm?"}
					onConfirm={() => {
						if (release == "all") {
							Claim(
								tableData.filter((x) => x.generated == false),
								"all"
							);
						} else {
							Claim(selectedCheckboxes, "selected");
						}
						setShowConfirmation(false);
					}}
					onCancel={() => {
						setShowConfirmation(false);
					}}
				>
					{t("UNABLE_TO_REVERT")}
				</SweetAlert>
			)}
			<h1 className="page-header">{t("COMMISSION_AND_REBATE")}</h1>
			<Panel>
				<PanelBody>
					<div className="row">
						<div
							className="input-group"
							style={{ width: "100%", flexFlow: "nowrap", marginTop: "14px", marginLeft: "25px" }}
						>
							<div className="input-group-prepend">
								<span className="input-group-text">
									<i className="fa fa-calendar"></i>
								</span>
							</div>
							<div style={{ width: "20%" }}>
								<DateRangePicker
									containerStyles={{ width: "100%" }}
									singleDatePicker={true}
									onApply={handleEvent}
									alwaysShowCalendars={true}
									locale={{ format: "YYYY-MM-DD" }}
									maxDate={moment().subtract(1, "days").format("YYYY-MM-DD")}

								>
									<input
										type="text"
										className="form-control"
										value={selectedDate}
									/>
								</DateRangePicker>
							</div>
							<button
								type="button"
								className="btn btn-primary"
								style={{ marginLeft: "12px" }}
								onClick={() => getRebateMemberList(calBy)}
							>
								{t("SEARCH")}
							</button>
						</div>
					</div>
				</PanelBody>
			</Panel>
			<Panel>
				<PanelBody>
					<div className="row">
						<div className="col-lg-6 d-flex" style={{ gap: "0.5rem" }}>
							<button
								className="btn btn-primary"
								// style={{ marginLeft: "25px", marginTop: "30px", width: "100px", position: "absolute", zIndex: "1" }}
								onClick={() => {
									setRelease("selected");
									setShowConfirmation(true);
								}}
								disabled={selectedCheckboxes.length > 0 ? false : true}
							>
								{t("RELEASE")}
							</button>
							<button
								className="btn btn-primary"
								// style={{ marginLeft: "140px", marginTop: "30px", width: "100px", position: "absolute", zIndex: "1" }}
								onClick={() => {
									setRelease("all");
									setShowConfirmation(true);
								}}
								disabled={
									tableData.filter((x) => x.generated == false).length > 0
										? false
										: true
								}
							>
								{t("RELEASE_ALL")}
							</button>
						</div>
					</div>
				</PanelBody>
			</Panel>
			<Panel>
				<div className="row">
					<div className="col-lg-12">
						{
							<ReactTable
								data={tableData}
								columns={_tableColumns}
								renderFooter={true}
							/>
						}
					</div>
				</div>
			</Panel>
		</div>
	);
};

export default ManageMemberRebate;
